jQuery.event.special.touchstart = {
    setup: function (_, ns, handle) {
      if (ns.includes("noPreventDefault")) {
        this.addEventListener("touchstart", handle, { passive: false });
      } else {
        this.addEventListener("touchstart", handle, { passive: true });
      }
    }
  };
  
jQuery.event.special.touchmove = {
  setup: function (_, ns, handle) {
    if (ns.includes("noPreventDefault")) {
      this.addEventListener("touchmove", handle, { passive: false });
    } else {
      this.addEventListener("touchmove", handle, { passive: true });
    }
  }
};
  
$("a.nav-link").on('click', function () {
  var navItem = $(this).closest(".nav-item");
  
  if (!navItem.hasClass("active")) {
      navItem.toggleClass("active");
      $(".nav-item").not(navItem).removeClass('active');
  }
});

/* OK
$('.single-product-titles h3').hover(
  function() {
    var index = $(this).index();
    $('.single-product-images .image').eq(index).addClass('active');
  },
  function() {
    var index = $(this).index();
    $('.single-product-images .image').eq(index).removeClass('active');
  }
);*/
$('.single-product-titles h3').hover(
  function() {
    var index = $(this).index();
    $(this).addClass('active'); // Add active class to the hovered title

    // Find the corresponding image in the current tab and add the active class
    $(this).closest('.tab-pane').find('.single-product-images .image').eq(index).addClass('active');

    // Hide the default image in the current tab
    $(this).closest('.tab-pane').find('.single-product .image.default').addClass('hide');
  },
  function() {
    var index = $(this).index();
    $(this).removeClass('active'); // Remove active class from the title

    // Remove active class from the corresponding image in the current tab
    $(this).closest('.tab-pane').find('.single-product-images .image').eq(index).removeClass('active');

    // Show the default image in the current tab
    $(this).closest('.tab-pane').find('.single-product .image.default').removeClass('hide');
  }
);

// Bootstrap tab activation
$('.nav-pills a').on('show.bs.tab', function (e) {
  var tabId = $(this).attr('aria-controls');
  var tabContent = $('#' + tabId);

  // Remove the active class from other tabs
  $('.tab-pane').not(tabContent).removeClass('active show');

  // Show the default image for the active tab
  tabContent.find('.image.default').removeClass('hide');

  // Remove the active class from product titles and images within the tab
  tabContent.find('.single-product-titles h3').removeClass('active');
  tabContent.find('.single-product-images .image').removeClass('active');
});

$('.single-product-titles h3').on('click', function() {
  var index = $(this).index();
  var $image = $('.single-product-images .image').eq(index);

  if ($image.hasClass('clicked')) {
    $image.removeClass('clicked');
  } else {
    $image.addClass('clicked');
  }
});

$('.dropdown-toggle').dropdown();
  
  function checkWidth() {
    if ($(window).width() < 975) {
      $(".navi li ul").hide();
  
    } else {
      $(".navi li ul").attr('style', '');
  
    }
  }
  
  checkWidth();
  
  $(window).on("resize", function () {
    checkWidth();
    $('a.collapse').hide();
    $('a.expand').show();
  });
  
  $(".navi li").each(function () {
    var li = $(this);
    if (li.find('ul li').length > 0) {
      li.append("<a href='javascript:;' rel='nofollow noopener' class='expand icon-expand' onclick='return false;'></a>");
      li.append("<a href='javascript:;' rel='nofollow noopener' class='collapse icon-collapse' onclick='return false;'></a>");
    }
  });
  
  $('body').on('click', 'a.expand', function () {
    $(this).hide().next().show();
    $(this).parent().siblings().find('ul').slideUp();
    $(this).parent().siblings().find('a.expand').show();
    $(this).parent().siblings().find('a.collapse').hide();
    $(this).parent().find('>ul').addClass('expanded').stop(true, true).slideDown();
  });
  
  $('body').on('click', 'a.collapse', function () {
    $(this).parent().find('ul').removeClass('expanded').stop(true, true).slideUp();
    $(this).parent().find('a.collapse').hide();
    $(this).parent().find('a.expand').show();
  });
  
  $(".menu-btn").on('click', function () {
    $(".menu-btn").toggleClass("is-active");
    $(".header-container").toggleClass("navi-open");
    $("body").toggleClass("navi-open");
    $("body").css("overflow", ""); // Reset the body overflow property
  });
